<template>
  <v-container fluid>
    <page-header title="所有用戶">
      <template #rightSlot>
        <v-btn depressed color="primary accent-4" class="white--text" :to="{ name: 'CreateUser' }">新增</v-btn>
      </template>
    </page-header>

    <datatable
      :tableHeaders="tableHeaders"
      :tableData="tableData"
      :page.sync="tablePage"
      :pageLimit.sync="tablePageLimit"
      :itemTotal="tableItemTotal"
      :isLoading="tableLoading"
      @options-update="getList()"
      @view-clicked="navDetail($event)"
    >
      <template #custom-toolbar-slot>
        <form-select
          class="my-2"
          :fieldValue.sync="userType"
          :options="$userTypeOptions"
          hideDetails
          enabledClear
          placeholder="搜尋用戶類別"
          @changed="search()"
        />
      </template>
      <template #item.user_type="{ item }">{{ item.user_type | formatUserType }} </template>
      <template #item.admin_verified="{ item }">
        <span v-if="item.admin_verified === true">已批核</span>
        <span v-else-if="item.admin_verified === false" class="error--text">待批核</span>
        <span v-else>{{ item.admin_verified }}</span>
      </template>
    </datatable>
  </v-container>
</template>

<script>
import Datatable from '@/components/Datatable.vue'
import FormSelect from '@/components/form/FormSelect.vue'

export default {
  name: 'UserList',
  components: {
    Datatable,
    FormSelect,
  },
  data: () => ({
    tableLoading: false,
    tablePage: 1,
    tablePageLimit: 10,
    tableData: [],
    tableItemTotal: 0,
    userType: '',

    // --- static data
    tableHeaders: [
      { text: '用戶類別', value: 'user_type' },
      { text: '用戶名稱', value: 'username' },
      { text: '電郵地址', value: 'email' },
      { text: '電話', value: 'phone' },
      { text: '狀態', value: 'status' },
      { text: '審核狀態', value: 'admin_verified' },
      { text: '', value: 'actionView', align: 'end', width: 150 },
    ],
  }),
  methods: {
    getQueries() {
      const query = this.$route.query
      if (this.$validate.regexNumber(query['p']) && parseInt(query['p']) > 0) {
        this.tablePage = parseInt(query['p'])
      }

      if (this.$validate.regexNumber(query['l']) && parseInt(query['l']) > 0) {
        this.tablePageLimit = parseInt(query['l'])
      }

      if (this.$validate.regexNumber(query['u']) && parseInt(query['u']) > 0) {
        this.userType = parseInt(query['u'])
      }
      this.getList(true)
    },
    async getList(pageInit = false) {
      if (!pageInit) {
        const newQuery = {
          p: this.$validate.regexNumber(this.tablePage) && parseInt(this.tablePage) > 1 ? this.tablePage : undefined,
          l:
            this.$validate.regexNumber(this.tablePageLimit) && parseInt(this.tablePageLimit) !== 10
              ? this.tablePageLimit
              : undefined,
          u: this.userType ? this.userType : undefined,
        }

        const validNewQuery = this.$common.validNewQuery(this.$route.query, newQuery)
        if (validNewQuery) {
          this.$router.replace({ query: newQuery })
        }
      }

      this.tableLoading = true

      try {
        let payload = {
          limit: this.tablePageLimit,
          page: this.tablePage - 1,
        }

        if (this.userType === 'student') {
          payload['search_type'] = 'student'
        } else if (this.userType === 'tutor') {
          payload['search_type'] = 'tutor'
        }

        const user = this.getCurrentUserData()
        const { data, total } = await this.$Fetcher.GetUsers(payload, user.id, user.token)
        this.tableItemTotal = total

        if (!data || data.length < 1) {
          throw null
        }

        this.tableData = data.map(el => {
          return {
            id: el.id,
            user_type: el.data_type === 'tutor_data' ? 'tutor' : el.data_type === 'student_data' ? 'student' : '',
            username: el.name,
            email: el.email,
            phone: el.phone,
            status: el.status,
            admin_verified: el.data_type === 'student_data' ? '-' : el.admin_verified,
          }
        })
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.$store.dispatch('toggleSnack', {
            type: 'error',
            message: err,
          })
        }
        this.tableData = []
      } finally {
        this.tableLoading = false
      }
    },

    search() {
      this.tablePage = 1
      this.getList()
    },
    navDetail(id) {
      const found = this.tableData.find(el => el.id === id)
      if (found) {
        this.$router.push({ name: 'UserDetail', params: { id: id, type: found.user_type } })
      }
    },
  },

  mounted() {
    this.getQueries()
  },
}
</script>
